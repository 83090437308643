import { createDate, getLuxonNow, isBetween } from "./dateHelpers"

const isSessionActive = (date, session) => {
  if (Array.isArray(session?.content?.slots)) {
    return session.content.slots
      .map(({ start, end }) => ({
        start: createDate(start),
        end: createDate(end),
      }))
      .map(({ start, end }) => isBetween(date, start, end))
      .includes(true)
  }

  return false
}

export const isSessionNow = (session) => isSessionActive(getLuxonNow(), session)

const MINUTES_IN_ADVANCE = 30

export const isSessionUpcoming = (session) => {
  const advancedTime = getLuxonNow().plus({
    minutes: MINUTES_IN_ADVANCE,
  })

  if (Array.isArray(session?.content?.slots)) {
    const isUpcoming = session.content.slots
      .map(({ start, end }) => ({
        start: createDate(start),
        end: createDate(end),
      }))
      .map(({ start, end }) => advancedTime > start && advancedTime < end)

    return isUpcoming.includes(true)
  }

  return false
}

export const isSessionEnded = (session) => {
  const now = getLuxonNow()

  if (Array.isArray(session?.content?.slots)) {
    const hasAllSlotsEnded = session.content.slots
      .map(({ end }) => ({
        end: createDate(end),
      }))
      .map(({ end }) => now > end)
      .includes(false)

    return !hasAllSlotsEnded
  }

  return false
}
