/* eslint-disable react/prop-types */
import React, { useMemo } from "react"
import propTypes from "prop-types"
import {
  Box,
  Heading,
  Text,
  Center,
  Flex,
  SlideFade,
  Spacer,
  Alert,
  useMediaQuery,
} from "@chakra-ui/react"
import { BiTimeFive } from "react-icons/bi"
import { Link, useLocation } from "react-router-dom"
import { Tags } from "../Tags"
import { createDate, formatDateToTime } from "../../../util/dateHelpers"
import { durationToHumanReadable } from "../../../util/durationToHumanReadable"
import { timeStampsToDurations } from "../../../util/timeStampsToDurations"
import { TopRating } from "./TopRating"
import useSession from "../../../util/useSession"

import { SessionCardBadges } from "./SessionCardBadges"
import { AddToScheduleButton } from "./AddToScheduleButton"

const EXCERPT_MAX_LENGTH = 150

export function SessionCard({
  session,
  className,
  onClick,
  onlyShowMinimumInformation,
}) {
  const location = useLocation()
  const { content } = session
  const { sessionTags, description, title } = content
  const [isLargerThan820] = useMediaQuery([
    "(min-width: 481px)",
    "(min-width: 821px)",
  ])

  const { start, end } = session.content.slots[0]
  const { error, speakers, isTopSession, timing } = useSession(session.id)

  const time = useMemo(
    () => durationToHumanReadable(timeStampsToDurations(session.content.slots)),
    [session.content.slots[0]]
  )

  const startingTime = createDate(start)
  const endingTime = createDate(end)

  let excerpt = description

  if (description.length > 150) {
    excerpt = `${description.substring(0, EXCERPT_MAX_LENGTH)}...`
  }

  return (
    <SlideFade
      in
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      {error && (
        <Alert
          w={isLargerThan820 ? "560px" : "100%"}
          m="auto"
          borderRadius="10px"
          boxShadow="2xl"
          status="error"
        >
          {error.message}
        </Alert>
      )}
      <Box
        transition="0.3s ease"
        boxShadow="lg"
        _hover={{ boxShadow: "md" }}
        borderRadius="16px 16px 32px 16px"
        bg="#ffffff"
        className={className}
        overflow="hidden"
        width="100%"
        display="flex"
        background="white"
        border="0.3px #E3E3E3 solid"
        position="relative"
      >
        <Box
          as={Link}
          position="relative"
          to={{
            pathname: `/sessions/${session.id}`,
            state: { prevPath: location.pathname },
          }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          width="100%"
          onClick={onClick}
        >
          <Flex flexDirection="column" p="25px 25px 19px 25px" flexGrow={1}>
            <Flex flexDirection="column">
              <Heading as="h3" fontSize="20" color="#DA0074" mb={1}>
                <Flex alignItems="center" gap={1}>
                  {title}
                </Flex>
              </Heading>
              <Spacer />
              <Text fontSize="14px" mb={2} color="#999999">
                {speakers
                  .map(
                    (speaker) =>
                      `${speaker.content.firstName} ${speaker.content.lastName}`
                  )
                  .join(", ")}
              </Text>
              <Spacer />
              {!onlyShowMinimumInformation && (
                <Text fontSize="16px" mb={2}>
                  {excerpt}
                </Text>
              )}
            </Flex>

            <Flex mt="auto" flexDir="column">
              {!onlyShowMinimumInformation && (
                <Flex gap="15px" direction="row" mt="3" justify="flex-start">
                  <SessionCardBadges
                    oldSession={timing.isOldSession}
                    isSessionUpcoming={timing.isSessionUpcoming}
                    isSessionNow={timing.isLive}
                  />
                  <Flex justifyContent="space-between">
                    <Center>
                      <BiTimeFive size="16px" />
                    </Center>
                    <Flex alignItems="center" justifyContent="center">
                      <Text fontSize="16px" pl="1.5">
                        {time}
                      </Text>
                      <Text fontSize="14px" pl="2">
                        {formatDateToTime(startingTime.toJSDate())} -{" "}
                        {formatDateToTime(endingTime.toJSDate())}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Tags tags={sessionTags}>{isTopSession && <TopRating />}</Tags>
              </Box>
            </Flex>
          </Flex>
        </Box>
        {!onlyShowMinimumInformation && (
          <AddToScheduleButton sessionId={String(session.id)} />
        )}
      </Box>
    </SlideFade>
  )
}

SessionCard.propTypes = {
  onClick: propTypes.func.isRequired,
  session: propTypes.shape({}).isRequired,
  onlyShowMinimumInformation: propTypes.bool,
}

SessionCard.defaultProps = {
  onlyShowMinimumInformation: false,
}
