export const filterSessionsBySearchInput = ({ sessions, searchInput }) => {
  const lowerCaseValue = searchInput?.toLowerCase()

  const filteredSessions = sessions.filter((session) => {
    if (session.content.title?.toLowerCase().includes(lowerCaseValue)) {
      return true
    }

    if (session.content.description?.toLowerCase().includes(lowerCaseValue)) {
      return true
    }

    const filterTags = session?.content.sessionTags?.filter((tag) => {
      if (!tag) return false

      return !!tag.title?.toLowerCase().includes(lowerCaseValue)
    })

    return filterTags.length > 0
  })

  return filteredSessions
}
