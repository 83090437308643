import React, { useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useAxiosWithAuthentication } from "../../../hooks/useAxiosWithAuthentication"
import { FeedbackForm } from "./FeedbackForm"
import { FeedbackSuccess } from "./FeedbackSuccess"

export function SessionFeedback({ isOpen, onClose, title, id, onFeedback }) {
  const [{ data, loading, error }, execute] = useAxiosWithAuthentication(
    {
      url: `/api/v2/sessions/${id}/feedback`,
      method: "POST",
    },
    { manual: true }
  )

  const [formState, setFormState] = useState({
    rating: undefined,
    answer1: "",
    answer2: "",
  })

  const { t } = useTranslation()

  const onSubmit = (e) => {
    e.preventDefault()
    execute({
      data: {
        rating: formState.rating,
        questions: [
          {
            question: t("feedbackForm.q1"),
            answer: formState.answer1,
          },
          {
            question: t("feedbackForm.q2"),
            answer: formState.answer2,
          },
        ],
      },
    }).then(() => onFeedback())
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent ml="3" mr="3">
        <ModalCloseButton size="lg" />
        {!data && <ModalHeader>{t("giveFeedback")}</ModalHeader>}

        <ModalBody>
          {data ? (
            <FeedbackSuccess id={id} />
          ) : (
            <FeedbackForm
              sessionTitle={title}
              onSubmit={onSubmit}
              setFormState={setFormState}
              state={formState}
              loading={loading}
              error={error}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

SessionFeedback.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onFeedback: PropTypes.func.isRequired,
}
