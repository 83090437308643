export const translationEN = {
  timeSchedule: {
    header: "Your time schedule",
  },
  navBar: {
    timeSchedule: "Schedule",
    feedback: "Feedback",
  },
  filterTitle: "My Sessions",
  filterAlert: "Sorry, no results found",
  searchPlaceholder: "Search by title, description and tags",
  addSessionAlert: "Session added to your schedule",
  removeSessionAlert: "Session removed from your schedule",
  addSessionButton: "Add to my schedule",
  removeSessionButton: "Remove from my schedule",
  giveFeedback: "Give Feedback",
  sessionViewAlert: "Session not found",
  thankYouFeedback: "Thank you for your feedback",
  sessionViewRoom: "Room",
  sessionViewDescription: "Description",
  sessionViewHourUnit: "hr",
  feedbackSuccess: {
    goSession: "Session Page",
    goFeedback: "Feedback Page",
  },
  feedbackForm: {
    title: "Here you can give feedback anonymously to",
    howDoYouLikeTheSessionRating: "How did you like the session?*",
    q1: "What would you like to say to the presenters?",
    q2: "What do you take with you for your everyday work?",
    goodRate: "Good",
    veryGoodRate: "Very good",
    megaGoodRate: "Mega good",
    sendButton: "Send Feedback",
    thankYouMessage: "Thank you for your feedback",
  },
  feedback: {
    pendingFeedback: {
      title: "Feedback pending",
      everythingHasFeedback:
        "You gave feedback to every session that you have in your schedule. Thank you ❤️",
    },
    givenFeedback: {
      title: "Feedback given",
      missingFeedback: "Still waiting for your feedback 😎",
    },
  },
  weAppFeedback: {
    questionHowDoYouLikeTheApp: "How do you like the One App in general?*",
    modalHeader: "Give feedback about the One App",
    questionYourFeedback: "Your feedback*",
    questionYourFeedbackPlaceholder:
      "I like ... / I think we could improve ...",
    modalHintText: "Rating and your feedback are required fields.",
  },
  highlightTabs: {
    tabOne: {
      name: "Now",
      fallback: "Currently there are no ongoing sessions.",
    },
    tabTwo: {
      name: "Upcoming",
      fallback: "No upcoming sessions for the next 30 minutes.",
    },
  },
  topSession: {
    title: "Top Session",
  },
  footer: {
    buttonTitle: "Give feedback about the One App",
  },
  speakers: {
    jobTitle: "Job Title",
    unit: "Unit",
    location: "Location",
    description: "About Speaker",
  },
  materials: {
    title: "Documents / Files",
    error: "There are no documents or files.",
  },
  notFoundMessage: "Sorry, the page could not be found.",
  notFoundButtonText: "Back to main page",
  login: {
    welcomeText:
      "Find all the program items for this conference and put together your own schedule",
    buttonText: "Login with Okta",
    loginHelperTextUsername: "Username: e.g. firstname.lastname@interone.de",
    loginHelperTextPassword:
      "Password: same as your login password on your mac/pc",
  },
  introModal: {
    header: "Welcome",
    text: "Everything is possible?! Our annual one conference is taking place in Munich this year. Once again, it is great to see the potential and knowledge that all of us have at interone. With this app, you can put together your personal conference plan using the timetable. You will also find further information about the lectures and workshops, as well as an FAQ. Please also provide feedback for our speakers within this app.",
  },
}
