import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { isSessionNow, isSessionUpcoming } from "../../../util/session"
import { HighlightTabs } from "./HighlightTabs"
import { sortByStartingTime } from "../../../util/timeHelpers"

const sortSessions = (sessions) => {
  const currentlyActiveSessions = sortByStartingTime(
    sessions.filter(isSessionNow)
  )

  const upcomingSessions = sortByStartingTime(
    sessions
      .filter(isSessionUpcoming)
      .filter((s) => !currentlyActiveSessions.find(({ id }) => s.id === id))
  )

  const defaultIndex =
    currentlyActiveSessions.length === 0 && upcomingSessions.length > 0 ? 1 : 0

  return {
    activeSessions: currentlyActiveSessions,
    upcomingSessions,
    defaultIndex,
  }
}

export function HighlightedSessions({ sessions }) {
  const { t } = useTranslation()
  const [sessionState, setSessionState] = useState(sortSessions(sessions))

  useEffect(() => {
    const interval = setInterval(() => {
      setSessionState(sortSessions(sessions))
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [sessions])

  return (
    <HighlightTabs
      defaultIndex={sessionState.defaultIndex}
      tabs={[
        {
          name: `${t("highlightTabs.tabOne.name")}`,
          items: sessionState.activeSessions,
          fallback: `${t("highlightTabs.tabOne.fallback")}`,
          color: "gray.50",
        },
        {
          name: `${t("highlightTabs.tabTwo.name")}`,
          items: sessionState.upcomingSessions,
          fallback: `${t("highlightTabs.tabTwo.fallback")}`,
          color: "gray.100",
        },
      ]}
    />
  )
}

HighlightedSessions.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
