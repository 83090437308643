import { Box, Link, Heading, ModalFooter, VStack } from "@chakra-ui/react"
import { BsCheck2Circle } from "react-icons/bs"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

export function FeedbackSuccess() {
  const { t } = useTranslation()

  return (
    <VStack justify="center" alignItems="center" py={4}>
      <Box>
        <BsCheck2Circle size={60} />
      </Box>
      <Heading fontSize="md">{t("feedbackForm.thankYouMessage")}</Heading>
      <ModalFooter>
        <Link as={RouterLink} to="/feedback">
          Go to feedback overview
        </Link>
      </ModalFooter>
    </VStack>
  )
}
