import { useEffect, useState } from "react"
import { useAxiosWithAuthentication } from "../hooks/useAxiosWithAuthentication"
import { isSessionNow, isSessionUpcoming, isSessionEnded } from "./session"
import { isConferenceNow } from "./various"

const getTimingState = (session) => {
  if (!session || !isConferenceNow()) {
    return {
      isOldSession: false,
      isLive: false,
      isSessionUpcoming: false,
    }
  }

  if (isSessionNow(session)) {
    return {
      isOldSession: false,
      isLive: true,
      isSessionUpcoming: false,
    }
  }

  if (isSessionUpcoming(session)) {
    return {
      isOldSession: false,
      isLive: false,
      isSessionUpcoming: true,
    }
  }

  if (isSessionEnded(session)) {
    return {
      isOldSession: true,
      isLive: false,
      isSessionUpcoming: false,
    }
  }

  return {
    isOldSession: false,
    isLive: false,
    isSessionUpcoming: false,
  }
}

export default function useSession(id) {
  const [speakers, setSpeakers] = useState([])
  const [sessionType, setSessionType] = useState(undefined)

  const [{ data, loading, error }, refetch] = useAxiosWithAuthentication({
    url: `${process.env.REACT_APP_STORYBLOK_URL}${id}?version=${process.env.REACT_APP_STORYBLOK_VERSION}&token=${process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN}&cv=1675692580&resolve_relations=session.sessionType,session.speaker`,
  })

  const [timing, setTiming] = useState(getTimingState(data?.story))

  const [{ data: isTopSessionData }] = useAxiosWithAuthentication({
    url: `/api/v2/sessions/${id}/top-session`,
    method: "GET",
  })

  useEffect(() => {
    if (loading || !data || !data?.story) return

    const speakersData = []
    let relSessionType

    data.rels.forEach((res) => {
      if (res.content.component === "speaker") {
        speakersData.push(res)
      }

      if (res.content.component === "sessionType") {
        relSessionType = {
          title: res.content.title,
          styleName: res.content.style,
        }
      }
    })

    setSpeakers(speakersData)
    setSessionType(relSessionType)
  }, [data, loading])

  useEffect(() => {
    let interval

    if (data?.story) {
      setTiming(getTimingState(data?.story))

      interval = setInterval(() => {
        setTiming(getTimingState(data?.story))
      }, 1000 * 60)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [data])

  return {
    data,
    loading,
    error,
    refetch,
    speakers,
    sessionType,
    isTopSession: isTopSessionData?.isTopSession,
    timing,
  }
}
