import React, { forwardRef, useRef } from "react"
import { Box, Container } from "@chakra-ui/react"
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs"
import "swiper/css"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"
import { SessionCard } from "../SessionListView/SessionCard"
import "../SessionListView/session-tabs.scss"

const SlideArrow = forwardRef(({ type }, ref) => (
  <Box
    as="button"
    type="button"
    ref={ref}
    position="absolute"
    left={type === "next" ? "auto" : "-0.25rem"}
    right={type === "next" ? "-0.25rem" : "auto"}
    top="50%"
    height="100%"
    width="2.25rem"
    transform="translateY(-50%)"
    color="#5550FF"
    sx={{
      svg: {
        width: "100%",
        height: "100%",
      },
    }}
  >
    {type === "prev" ? <BsChevronCompactLeft /> : <BsChevronCompactRight />}
  </Box>
))

SlideArrow.propTypes = {
  type: PropTypes.oneOf(["prev", "next"]).isRequired,
}

export function SessionCardSlider({ items }) {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <Container position="relative">
      <SlideArrow ref={prevRef} type="prev" />

      <Swiper
        modules={[Navigation]}
        spaceBetween={20}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
      >
        {items.map((session) => (
          <SwiperSlide key={session.id}>
            <Box pb={4}>
              <SessionCard session={session} onClick={() => {}} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      <SlideArrow ref={nextRef} type="next" />
    </Container>
  )
}

SessionCardSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
