import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "linear-gradient(135deg, #FFFFFF 0%, #F2F2F2 100%)",
      },
    }),
  },
  colors: {
    primary: {
      900: "#63bedd",
      500: "#63bedd",
    },
    gray: {
      800: "#191919",
    },
    solidBlack: {
      900: "#151515",
    },
    vibrant: {
      800: "#5550FF",
    },
  },
  components: {
    Container: {
      baseStyle: {
        paddingInlineStart: "1.5rem",
        paddingInlineEnd: "1.5rem",
      },
    },
    Link: {
      baseStyle: {
        color: "primary.900",
        textDecoration: "underline",
      },
    },
  },
})

export default theme
