import React from "react"
import { Helmet } from "react-helmet-async"
import { SessionView } from "../components/sessions/SessionListView/SessionView"

export function SessionsRoute() {
  return (
    <>
      <Helmet>
        <title>Sessions | One App</title>
      </Helmet>
      <SessionView />
    </>
  )
}
