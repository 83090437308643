import React from "react"
import { Tag, HStack } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ReactComponent as Award } from "../../../assets/svg/award.svg"

export function TopRating() {
  const { t } = useTranslation()

  return (
    <Tag
      fontSize="12px"
      color="white"
      background="#7B3EE4"
      bg="transparent"
      borderRadius="100px"
      padding="8px"
      border="1px solid #7B3EE4"
      sx={{
        marginBottom: "0.5em !important",
        marginInlineStart: "0px !important",
        marginInlineEnd: "0.5em !important",
      }}
    >
      <HStack>
        <div>
          <Award />
        </div>
        <div>{t("topSession.title")}</div>
      </HStack>
    </Tag>
  )
}

TopRating.propTypes = {}
