import { useOktaAuth } from "@okta/okta-react"
import useAxios from "axios-hooks"

export const useAxiosWithAuthentication = (config, settings = undefined) => {
  const { oktaAuth } = useOktaAuth()

  const configWithAuth = { ...config }
  configWithAuth.headers = configWithAuth.headers || {}
  configWithAuth.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`

  return useAxios(configWithAuth, settings)
}
