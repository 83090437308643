import { Alert, AlertIcon } from "@chakra-ui/react"
import React from "react"

export function NoExistingSessions() {
  return (
    <Alert status="info">
      <AlertIcon />
      <div>You have no sessions added to your schedule. </div>
    </Alert>
  )
}
