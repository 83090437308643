import { DateTime } from "luxon"
import { getLuxonNow } from "./dateHelpers"

export const conferenceStart = DateTime.local(2024, 6, 5, 15, 0, 0, 0)
export const conferenceSessionStart = DateTime.local(2024, 6, 6, 8, 0, 0, 0)
export const conferenceEnd = DateTime.local(2024, 6, 6, 20, 0, 0, 0)

export const isConferenceNow = () => {
  const now = getLuxonNow()

  return now >= conferenceSessionStart && now <= conferenceEnd
}

export const currentEventName = "one-conference-2024"
export const STORYBLOK_CONFERENCE_ID = 473942149
const STORYBLOK_CONFERENCE_UUID = "16445953-35fc-45dc-82c7-c7e1912d00a5"
export const STORYBLOK_FILTER_FOR_CONFERENCE = `&filter_query[eventReference][in]=${STORYBLOK_CONFERENCE_UUID}`
