import { CLIENT_ID, ISSUER } from "./okta.config"

const BASENAME = process.env.PUBLIC_URL || ""
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`

export const oktaConfig = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
}
