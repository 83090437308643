import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import propTypes from "prop-types"
import styles from "./button.module.scss"

export function IoButton(props) {
  const {
    as,
    children,
    hierarchy,
    background,
    className,
    iconOnly,
    fullWidth,
    ...rest
  } = props

  const classname = classNames(
    "button",
    styles.button,
    {
      [styles.primary]: hierarchy === "primary",
      [styles.primaryOnWhite]:
        background === "onWhite" && hierarchy === "primary",
      [styles.primaryOnBlack]:
        background === "onBlack" && hierarchy === "primary",
      [styles.secondary]: hierarchy === "secondary",
      [styles.secondaryOnWhite]:
        background === "onWhite" && hierarchy === "secondary",
      [styles.secondaryOnBlack]:
        background === "onBlack" && hierarchy === "secondary",
      [styles.iconOnly]: iconOnly,
      [styles.vibrant]: hierarchy === "vibrant",
      [styles.fullWidth]: fullWidth,
    },
    className
  )

  const inner = (
    <div>
      <span>{children}</span>
    </div>
  )

  if (as === "link") {
    const { href, ...restLink } = rest

    if (href && href.startsWith("http")) {
      return (
        <a
          target="_blank"
          rel="noreferrer noopener"
          className={classname}
          href={href}
          {...restLink}
        >
          {inner}
        </a>
      )
    }

    return (
      <Link {...restLink} href={`/${href}` || "/"} className={classname}>
        {inner}
      </Link>
    )
  }

  if (as === "button") {
    return (
      <button type="button" {...rest} className={classname}>
        {inner}
      </button>
    )
  }

  throw new Error("could not determine the correct button type")
}

IoButton.propTypes = {
  className: propTypes.string,
  children: propTypes.node.isRequired,
  as: propTypes.string.isRequired,
  hierarchy: propTypes.string.isRequired,
  iconOnly: propTypes.bool,
  background: propTypes.string,
  fullWidth: propTypes.bool,
}

IoButton.defaultProps = {
  background: undefined,
  iconOnly: false,
  className: "",
  fullWidth: true,
}
