import React from "react"
import { Skeleton, Box, Container } from "@chakra-ui/react"

export function SessionDetailViewLoading() {
  return (
    <>
      <Container maxW="2xl" pt={7}>
        <Box mb={10}>
          <Skeleton
            width="92%"
            height="40px"
            borderRadius="30px"
            top="65px"
            left="25px"
            mb={10}
          />
          <Skeleton width="62%" height="18px" borderRadius="30px" mb={3} />
          <Skeleton width="68%" height="18px" borderRadius="30px" />
        </Box>
      </Container>

      <Container>
        <Box>
          <Skeleton width="25%" height="18px" borderRadius="30px" mb={3} />
          <Skeleton width="40%" height="18px" borderRadius="30px" mb={3} />
          <Skeleton width="38%" height="18px" borderRadius="30px" mb={10} />
        </Box>
        <Box>
          <Skeleton width="100%" height="50px" borderRadius="30px" mb={3} />
          <Skeleton width="100%" height="50px" borderRadius="30px" mb={10} />
        </Box>
        <Box mb={10}>
          <Skeleton width="133px" height="18px" borderRadius="30px" mb={3} />
          <Skeleton width="237px" height="18px" borderRadius="30px" mb={3} />
        </Box>
      </Container>
    </>
  )
}
