import React from "react"
import { Box, Container, Text } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { ExternalLink } from "../../ExternalLink"
import { isConferenceNow } from "../../../util/various"

const fallbacks = [
  "Have a break, have a Coffee.",
  <ExternalLink href="https://theuselessweb.com/">
    Take me to the useless web.
  </ExternalLink>,
  <>
    Keine Ausreden! Jetzt hättest du Zeit für{" "}
    <ExternalLink href="https://www.youtube.com/watch?v=uOOsMloibJQ">
      Sport
    </ExternalLink>
    .
  </>,
  <>
    <ExternalLink href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
      Durchhalten!
    </ExternalLink>{" "}
    Nicht mehr lange bis zur nächsten Session.
  </>,
  "Time for snacks",
  "Was ist das Lieblingsgericht von Bibern: Steg.",
  "Ich habe vorhin beim Brötchen angerufen. War belegt.",
]

export function FallbackWithRandom({ fallback }) {
  const PERCENTAGE = 0.1
  const hasRandomFallback = isConferenceNow() && Math.random() < PERCENTAGE
  const randomIndex = Math.floor(Math.random() * fallbacks.length)

  return (
    <Container pb={4}>
      <Text>
        {fallback}
        {hasRandomFallback && <Text as="i"> Daher:</Text>}
      </Text>
      {hasRandomFallback && (
        <Box pt={2}>
          <Text as="i">{fallbacks[randomIndex]}</Text>
        </Box>
      )}
    </Container>
  )
}

FallbackWithRandom.propTypes = {
  fallback: PropTypes.string.isRequired,
}
