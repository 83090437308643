import React, { useEffect, useState } from "react"
import {
  Container,
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
} from "@chakra-ui/react"

import StoryblokClient from "storyblok-js-client"
import { Helmet } from "react-helmet-async"
import { PageHeader } from "../PageHeader"
import getFAQ from "../../util/getFAQ"
import "./faqStyles.scss"

export function FaqView() {
  const { faqs, loading, error } = getFAQ()
  const [sortedFaqData, setSortedFaqData] = useState([])

  useEffect(() => {
    if (loading || !faqs) return

    const sortedData = faqs.sort((a, b) => b.isImportant - a.isImportant)
    setSortedFaqData(sortedData)
  }, [faqs])

  const importantFaq = sortedFaqData.filter((faqitem) => faqitem.isImportant)
  const normalFaq = sortedFaqData.filter((faqitem) => !faqitem.isImportant)

  if (error) {
    return (
      <>
        <Helmet>
          <title>FAQ | One App</title>
        </Helmet>
        <Container pt={5}>
          <Alert borderRadius="10px" boxShadow="2xl" status="error">
            {error.message}
          </Alert>
        </Container>
      </>
    )
  }

  const accessToken = process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN
  const Storyblok = new StoryblokClient({
    accessToken,
  })

  return (
    <Container maxW="2xl">
      <Helmet>
        <title>FAQ | One App</title>
      </Helmet>
      <PageHeader title="FAQ" />
      {importantFaq.length > 0 && (
        <Accordion
          pt="5"
          allowToggle
          sx={{ borderBottom: "0.1px solid lightgrey" }}
        >
          {importantFaq.map((faqitem) => (
            <AccordionItem key={faqitem.title} sx={{ border: "none" }}>
              <AccordionButton sx={{ borderTop: "0.1px solid lightgrey" }}>
                <Box flex="1" textAlign="left" py={1.5}>
                  <Heading fontSize="l">{faqitem.title}</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <div
                  className="faq__answer"
                  /* eslint-disable */
                  dangerouslySetInnerHTML={{
                    __html: Storyblok.richTextResolver.render(
                      faqitem.description
                    ),
                  }}
                  /* eslint-disable */
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
      <br />
      {normalFaq.length > 0 && (
        <Accordion
          pt="5"
          allowToggle
          sx={{ borderBottom: "0.1px solid lightgrey" }}
        >
          {normalFaq.map((faqitem) => (
            <AccordionItem key={faqitem.title} sx={{ border: "none" }}>
              <AccordionButton sx={{ borderTop: "0.1px solid lightgrey" }}>
                <Box flex="1" textAlign="left" py={1.5}>
                  <Heading fontSize="l">{faqitem.title}</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <div
                  className="faq__answer"
                  /* eslint-disable */
                  dangerouslySetInnerHTML={{
                    __html: Storyblok.richTextResolver.render(
                      faqitem.description
                    ),
                  }}
                  /* eslint-disable */
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Container>
  )
}
