import React from "react"
import { Alert, Box, Container, Flex, Spinner } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { ScheduleCalendar } from "./ScheduleCalendar"
import { PageHeader } from "../PageHeader"
import { ScheduleDaySelection } from "./ScheduleDaySelection"
import { NoExistingSessions } from "./NoExistingSessions"
import { useScheduleTimeslots } from "../../hooks/useScheduleTimeslots"

export function ScheduleView() {
  const { t } = useTranslation()
  const {
    timeslots,
    loading,
    error,
    datesWithTalks,
    selectedDate,
    setActiveDate,
  } = useScheduleTimeslots()

  return (
    <>
      <Helmet>
        <title>{t("timeSchedule.header")} | One App</title>
      </Helmet>
      <Container maxW="2xl">
        <Flex alignItems="center">
          {loading && (
            <Box mr={5}>
              <Spinner />
            </Box>
          )}
          <PageHeader title={t("timeSchedule.header")} />
        </Flex>

        {error && (
          <Alert borderRadius="10px" boxShadow="2xl" status="error">
            {error.message}
          </Alert>
        )}

        {!error && (
          <>
            <ScheduleDaySelection
              activeDate={selectedDate}
              dates={datesWithTalks}
              onDateChange={setActiveDate}
            />

            {!loading && timeslots.length === 0 ? (
              <Box mt={5}>
                <NoExistingSessions />
              </Box>
            ) : (
              <ScheduleCalendar date={selectedDate} events={timeslots} />
            )}
          </>
        )}
      </Container>
    </>
  )
}
