import { Redirect, Route, Switch } from "react-router-dom"
import { LoginCallback, SecureRoute } from "@okta/okta-react"
import React from "react"
import { LoginRoute } from "./LoginRoute"
import { withBottomNavigation } from "../hocs/withBottomNavigation"
import { SessionDetailRoute } from "./SessionDetailRoute"
import { SessionsRoute } from "./SessionsRoute"
import { ScheduleRoute } from "./ScheduleRoute"
import { FaqRoute } from "./FaqRoute"
import { NotFoundRoute } from "./NotFoundRoute"
import { FeedbackRoute } from "./FeedbackRoute"

export function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={LoginRoute} />
      <Route path="/login/callback" component={LoginCallback} />
      <Redirect exact path="/" to="/sessions" />
      <SecureRoute
        path="/sessions/:id"
        component={withBottomNavigation(SessionDetailRoute)}
      />
      <SecureRoute
        path="/sessions"
        component={withBottomNavigation(SessionsRoute)}
      />
      <SecureRoute
        path="/schedule"
        component={withBottomNavigation(ScheduleRoute)}
      />
      <SecureRoute
        path="/feedback"
        component={withBottomNavigation(FeedbackRoute)}
      />

      <SecureRoute path="/faq" component={withBottomNavigation(FaqRoute)} />
      <SecureRoute component={withBottomNavigation(NotFoundRoute)} />
    </Switch>
  )
}
