export const durationToHumanReadable = (luxonDuration) => {
  let { minutes, hours } = luxonDuration

  // fixes display of 2h 60min when two 1,5h slots are added up
  if (minutes === 60) {
    minutes = 0
    hours += 1
  }

  if (!hours) {
    return `${minutes} min`
  }

  minutes = minutes > 0 ? `:${minutes}` : ""

  return `${hours}${minutes} h`
}
