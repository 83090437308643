import React, { useState, useEffect } from "react"
import propTypes from "prop-types"
import {
  Container,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react"
import { CloseIcon, SearchIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { filterSessionsBySearchInput } from "./searchSessions"

export function SessionSearch({ sessions, onSearchResults }) {
  const [searchText, setSearchText] = useState("")
  const [textTyped, setTextTyped] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (searchText.length === 0) {
      onSearchResults(undefined)
      return
    }
    if (searchText.length > 0) {
      setTextTyped(true)
    }
    const filteredSessions = filterSessionsBySearchInput({
      sessions,
      searchInput: searchText,
    })

    onSearchResults(filteredSessions, textTyped)
  }, [searchText])

  return (
    <Container mb={5}>
      <Stack spacing={4} w="100%">
        <InputGroup>
          <Input
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            placeholder={t("searchPlaceholder")}
          />

          {searchText.length > 0 ? (
            <InputRightElement
              cursor="pointer"
              onClick={() => setSearchText("")}
            >
              <CloseIcon color="#5550ff" />
            </InputRightElement>
          ) : (
            <InputRightElement pointerEvents="none">
              <SearchIcon color="#5550ff" />
            </InputRightElement>
          )}
        </InputGroup>
      </Stack>
    </Container>
  )
}

SessionSearch.propTypes = {
  sessions: propTypes.arrayOf(propTypes.shape({}).isRequired).isRequired,
  onSearchResults: propTypes.func.isRequired,
}
