import { Box } from "@chakra-ui/react"
import React from "react"
import PropTypes from "prop-types"

export function PageHeader({ title }) {
  return (
    <Box className="general-font-lg " fontWeight="bold" mb="5" mt={4}>
      {title}
    </Box>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
}
