import React from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { Security } from "@okta/okta-react"
import { HelmetProvider } from "react-helmet-async"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { oktaConfig } from "./oktaConfig"
import "./global.scss"
import theme from "./colorTheme"
import { Routes } from "./routes"

const oktaAuth = new OktaAuth(oktaConfig)

export function App() {
  const history = useHistory()

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin))
  }

  const customAuthHandler = () => {
    history.push("/login")
  }

  return (
    <HelmetProvider>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <ChakraProvider theme={theme}>
          <Routes />
        </ChakraProvider>
      </Security>
    </HelmetProvider>
  )
}
