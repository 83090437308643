import React, { useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const INTRODUCTION_LOCAL_STORAGE_KEY = "didShowIntroductionModal2024"

export function IntroductionModal() {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { t } = useTranslation()

  useEffect(() => {
    try {
      const hasVisited = localStorage.getItem(INTRODUCTION_LOCAL_STORAGE_KEY)

      if (!hasVisited) {
        onOpen()

        localStorage.setItem(INTRODUCTION_LOCAL_STORAGE_KEY, "true")
      }
      // eslint-disable-next-line
    } catch {}
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent ml="3" mr="3">
        <ModalHeader>{t("introModal.header")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          <p>{t("introModal.text")}</p>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
