import React from "react"
import { Box, Text } from "@chakra-ui/react"
import propTypes from "prop-types"

export function SpeakerCard({ speaker }) {
  return (
    <Box>
      <Text fontWeight="bold">
        {speaker.firstName} {speaker.lastName}
      </Text>
      <Text color="#999999">
        {speaker.jobTitle} | {speaker.location} | {speaker.unit}
      </Text>
    </Box>
  )
}

SpeakerCard.propTypes = {
  speaker: propTypes.shape({
    firstName: propTypes.string.isRequired,
    lastName: propTypes.string.isRequired,
    jobTitle: propTypes.string.isRequired,
    location: propTypes.string.isRequired,
    unit: propTypes.string.isRequired,
  }).isRequired,
}
