import React from "react"
import { Container } from "@chakra-ui/react"
import { FeedbackList } from "./FeedbackList"
import { PageHeader } from "../PageHeader"

export function FeedbackView() {
  return (
    <>
      <Container maxW="2xl">
        <PageHeader title="Your feedback" />
      </Container>
      <FeedbackList />
    </>
  )
}
