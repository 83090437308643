import { Button, Center, Container } from "@chakra-ui/react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import React from "react"
import { useTranslation } from "react-i18next"

export function NotFoundView() {
  const { t } = useTranslation()
  return (
    <Container
      height="90vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>404 | One App</title>
      </Helmet>
      <Container>
        <Center p="3" fontSize="70px" color="orange" mb="10px">
          404
        </Center>
        <Center fontSize="13px" w="100%" mt="20px">
          {t("notFoundMessage")}
        </Center>
        <Center mt="20px">
          <Link to="/sessions">
            <Button>{t("notFoundButtonText")}</Button>
          </Link>
        </Center>
      </Container>
    </Container>
  )
}
