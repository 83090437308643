import { createDate } from "./dateHelpers"
import { onlyTimeWithoutDate } from "./convertDateAndTime"

export const sortByDate = (sessionData) =>
  sessionData.sort(
    (a, b) =>
      createDate(a.content.slots[0].start).valueOf() -
      createDate(b.content.slots[0].start).valueOf()
  )

export const sortByStartingTime = (sessionData) =>
  sessionData.sort((a, b) => {
    function buildDate(session) {
      return createDate(session.content.slots[0].start)
    }

    return buildDate(a) - buildDate(b)
  })

export const groupByDate = (sessions) =>
  sessions.reduce((group, session) => {
    const date = createDate(session.content.slots[0].start)
    const dateInISO = date.toISODate()

    const existingGroup = group[dateInISO]

    if (Array.isArray(existingGroup)) {
      existingGroup.push(session)
    } else {
      // eslint-disable-next-line no-param-reassign
      group[dateInISO] = [session]
    }

    return group
  }, {})

export const groupByStartingTime = (sessionData) => {
  const sessionsByStartingTime = {}

  sessionData.forEach((session) => {
    const { start } = session.content.slots[0]

    if (
      Object.prototype.hasOwnProperty.call(
        sessionsByStartingTime,
        onlyTimeWithoutDate(start)
      )
    ) {
      sessionsByStartingTime[onlyTimeWithoutDate(start)].items.push(session)
    } else {
      sessionsByStartingTime[onlyTimeWithoutDate(start)] = {
        time: createDate(session.content.slots[0].start),
        items: [session],
      }
    }
  })
  return sessionsByStartingTime
}
