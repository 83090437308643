import { Link } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import React from "react"
import PropTypes from "prop-types"

export function ExternalLink({ children, href }) {
  return (
    <Link href={href} target="_blank" rel="noreferrer" isExternal>
      {children}
      <ExternalLinkIcon mx="2px" />
    </Link>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
