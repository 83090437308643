import React from "react"
import { HStack, Tag } from "@chakra-ui/react"
import PropTypes from "prop-types"

export function Tags({ children, tags }) {
  return (
    <HStack flexWrap="wrap">
      {children}
      {tags
        .filter((tag) => tag)
        .map((tag) => (
          <Tag
            fontSize="12px"
            key={tag._uid}
            bg="transparent"
            borderRadius="100px"
            padding="8px"
            border="1px solid #151515"
            color="solidBlack.900"
            sx={{
              marginBottom: "0.5em !important",
              marginInlineStart: "0px !important",
              marginInlineEnd: "0.5em !important",
            }}
          >
            {tag.title}
          </Tag>
        ))}
    </HStack>
  )
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      _uid: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
}

Tags.defaultProps = {
  children: undefined,
}
