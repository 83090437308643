import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { SkeletonWE } from "../../Skeleton"

export function SessionLoadingList() {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <Box key={item} mt={5} style={{ display: "flex" }}>
          <Box width="100%" p={2} pt={3} mt={3} shadow="xl" borderRadius="10">
            <SkeletonWE height="10px" width="15%" mb={4} ml="3px" />
            <SkeletonWE height={25} width="30%" mb={4} />
            <SkeletonWE height={15} width="25%" mb={7} />
            <Flex flexDirection="column" align="start" mb={3}>
              <SkeletonWE height={15} width="60%" mb="10px" />
              <SkeletonWE height={15} width="60%" />
            </Flex>
            <Flex flexDirection="column" align="start" w="100%" mb={2}>
              <SkeletonWE height="20px" width="80%" mr={3} mb="10px" />
              <SkeletonWE height="20px" width="80%" mr={3} mb="10px" />
              <SkeletonWE height="20px" width="80%" mr={3} mb="10px" />
            </Flex>
            <Flex mt="20px">
              <SkeletonWE height="20px" width="10%" mr={3} mb="10px" />
              <SkeletonWE height="20px" width="10%" mr={3} mb="10px" />
              <SkeletonWE height="20px" width="10%" mr={3} mb="10px" />
              <SkeletonWE height="20px" width="10%" mr={3} mb="10px" />
            </Flex>
          </Box>
        </Box>
      ))}
    </>
  )
}
