import React, { useState } from "react"
import {
  Badge,
  Container,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpoint,
  useMediaQuery,
} from "@chakra-ui/react"
import PropTypes from "prop-types"
import { SessionCardSlider } from "./SessionCardSlider"
import { FallbackWithRandom } from "./FallbackWithRandom"

export function HighlightTabs({ tabs, defaultIndex }) {
  const [isLargerThan480] = useMediaQuery(["(min-width: 481px)"])
  const [tabIndex, setTabIndex] = useState(defaultIndex)
  const breakpoint = useBreakpoint()

  return (
    <Container maxW={isLargerThan480 && "100%"} padding={0}>
      <Tabs
        isFitted
        defaultIndex={defaultIndex}
        onChange={setTabIndex}
        mb={8}
        bg={tabs[tabIndex].color}
      >
        <Container padding={["base"].includes(breakpoint) ? 0 : undefined}>
          <TabList>
            {tabs.map((group) => (
              <Tab
                borderBottom="none"
                key={group.name}
                bg={group.color}
                color="#151515"
                _selected={{
                  color: "#5550FF",
                }}
              >
                {group.name}
                <Badge background="#5550FF" color="white" ml={2}>
                  {group.items.length}
                </Badge>
              </Tab>
            ))}
          </TabList>
        </Container>

        <TabPanels>
          {tabs.map((group) => (
            <TabPanel p={0} pt={4} key={group.name}>
              {group.items.length > 0 ? (
                <SessionCardSlider items={group.items} />
              ) : (
                <FallbackWithRandom fallback={group.fallback} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Container>
  )
}

HighlightTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      fallback: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultIndex: PropTypes.number.isRequired,
}
