import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import propTypes from "prop-types"
import styles from "./sessionCard.module.css"

function SessionCardisLiveBadge() {
  return (
    <Flex justifyItems="center" alignItems="center" ml={1}>
      <div className={`${styles.Rec} ${styles.button}`} />
      <Text color="red" textTransform="uppercase" fontWeight="bold" ml={1}>
        Live
      </Text>
    </Flex>
  )
}

SessionCardisLiveBadge.propTypes = {}

const TEXT_BY_TYPE = {
  passed: "Passed",
  upcoming: "Upcoming",
}

const COLOR_BY_TYPE = {
  passed: "#474747",
  upcoming: "#FFC700",
}

function TimingBadge({ type }) {
  const color = COLOR_BY_TYPE[type]
  return (
    <Flex alignItems="center" gap="5px">
      <Box width="12px" height="12px" borderRadius="full" background={color} />
      <Text
        color={color}
        fontSize="16px"
        textTransform="uppercase"
        fontWeight="bold"
      >
        {TEXT_BY_TYPE[type]}
      </Text>
    </Flex>
  )
}

TimingBadge.propTypes = {
  type: propTypes.string.isRequired,
}

export function SessionCardBadges({
  oldSession,
  isSessionUpcoming,
  isSessionNow,
}) {
  if (!oldSession && !isSessionUpcoming && !isSessionNow) return null

  return (
    <div>
      {oldSession && <TimingBadge type="passed" />}
      {isSessionUpcoming && <TimingBadge type="upcoming" />}
      {isSessionNow && <SessionCardisLiveBadge />}
    </div>
  )
}

SessionCardBadges.propTypes = {
  oldSession: propTypes.bool.isRequired,
  isSessionUpcoming: propTypes.bool.isRequired,
  isSessionNow: propTypes.bool.isRequired,
}
