import React, { useEffect, useState } from "react"
import { SimpleGrid, Box, Container, Flex } from "@chakra-ui/react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useAxiosWithAuthentication } from "../../hooks/useAxiosWithAuthentication"
import { SessionsList } from "../sessions/SessionListView/SessionsList"
import { AppFooter } from "../Footer"
import { SectionHeadline } from "../SectionHeadline"
import { ThreeDotLoader } from "../TheeDotLoader"
import { NoExistingSessions } from "../schedule/NoExistingSessions"

export function FeedbackList() {
  const { t } = useTranslation()

  const [sessionsWithoutFeedback, setSessionsWithoutFeedback] = useState([])
  const [sessionsWithFeedback, setSessionsWithFeedback] = useState([])

  const [{ data: scheduleData, loading }, refetchScheduleData] =
    useAxiosWithAuthentication({
      url: `/api/v2/schedule/`,
      method: "GET",
    })

  const [
    { data: myFeedback, loading: loadingFeedbackData },
    fetchFeedbackData,
  ] = useAxiosWithAuthentication({
    url: `/api/v2/sessions/feedback`,
    method: "GET",
  })

  useEffect(() => {
    refetchScheduleData()
    fetchFeedbackData()
  }, [])

  useEffect(async () => {
    if (loading || loadingFeedbackData) return
    if (!scheduleData?.data) return

    const allSessionPromises = scheduleData?.data.map(async (sessionId) =>
      axios
        .get(
          `${process.env.REACT_APP_STORYBLOK_URL}${sessionId}?version=${process.env.REACT_APP_STORYBLOK_VERSION}&token=${process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN}&cv=1675692580&`
        )
        .then((req) => req.data.story)
        .catch((e) => e)
    )

    const allSessions = await Promise.all(allSessionPromises)
    if (!allSessions) return

    const withFeedback = []
    const withoutFeedback = []

    allSessions.forEach((session) => {
      if (session instanceof Error) {
        return
      }

      const hasFeedback = myFeedback?.feedbacks.find(
        ({ sessionId }) => String(sessionId) === String(session.id)
      )

      if (hasFeedback) {
        withFeedback.push(session)
      } else {
        withoutFeedback.push(session)
      }
    })

    setSessionsWithFeedback(withFeedback)
    setSessionsWithoutFeedback(withoutFeedback)
  }, [scheduleData, myFeedback])

  if (loading) {
    return (
      <Container maxW="2xl" pt={5}>
        <Flex justifyContent="center" width="full">
          <Box width="50px">
            <ThreeDotLoader />
          </Box>
        </Flex>
      </Container>
    )
  }

  const hasSessionInSchedule = scheduleData?.data.length > 0

  if (!hasSessionInSchedule) {
    return (
      <Container maxW="2xl">
        <NoExistingSessions />
      </Container>
    )
  }

  const hasPendingFeedback = sessionsWithoutFeedback.length > 0
  const hasGivenFeedback = sessionsWithFeedback.length > 0

  return (
    <>
      <SectionHeadline>{t("feedback.pendingFeedback.title")}</SectionHeadline>

      <Container maxW="2xl">
        {hasPendingFeedback ? (
          <SimpleGrid columns={1} spacing="24px">
            <SessionsList
              onSessionCardClick={() => {}}
              sessions={sessionsWithoutFeedback}
              onlyShowMinimumInformation
            />
          </SimpleGrid>
        ) : (
          t("feedback.pendingFeedback.everythingHasFeedback")
        )}
      </Container>

      <Box mt="24px">
        <SectionHeadline>{t("feedback.givenFeedback.title")}</SectionHeadline>

        <Container maxW="2xl">
          {hasGivenFeedback ? (
            <SimpleGrid columns={1} spacing="24px">
              <SessionsList
                onSessionCardClick={() => {}}
                sessions={sessionsWithFeedback}
                onlyShowMinimumInformation
              />
            </SimpleGrid>
          ) : (
            t("feedback.givenFeedback.missingFeedback")
          )}
        </Container>
      </Box>

      <AppFooter />
    </>
  )
}
