import React from "react"
import { Helmet } from "react-helmet-async"
import { FaqView } from "../components/faq/FaqView"

export function FaqRoute() {
  return (
    <>
      <Helmet>
        <title>FAQ | One App</title>
      </Helmet>
      <FaqView />
    </>
  )
}
