import { DateTime } from "luxon"

export function getLuxonNow() {
  const params = new URLSearchParams(window.location.search)
  const dataFromParam = DateTime.fromISO(params.get("now"))

  if (dataFromParam.isValid) {
    return dataFromParam
  }

  return DateTime.now()
}

export function createDate(dateTimeString) {
  return DateTime.fromFormat(`${dateTimeString}`, "yyyy-MM-dd HH:mm")
}

export function formatDateLong(date) {
  return Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(date)
}

export function formatDateShort(date) {
  return Intl.DateTimeFormat("de-DE", { dateStyle: "short" }).format(date)
}
export function formatNowDate(date) {
  return Intl.DateTimeFormat("de-DE", { dateStyle: "short" }).format(date)
}

export function formatDateToTime(date) {
  return Intl.DateTimeFormat("de-DE", {
    timeStyle: "short",
  }).format(date)
}

export const minTime = new Date()
minTime.setHours(8, 0, 0)
export const maxTime = new Date()
maxTime.setHours(20, 0, 0)

function closestNumber(goal, options) {
  return options.reduce((prev, curr) =>
    Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
  )
}

export function getNearestDate(currentDate, dates) {
  if (dates.length === 0) return undefined

  const matchingCurrentDate = dates.find(
    (d) =>
      d.getFullYear() === currentDate.getFullYear() &&
      d.getDate() === currentDate.getDate() &&
      d.getMonth() === currentDate.getMonth()
  )

  if (matchingCurrentDate) return matchingCurrentDate

  return closestNumber(currentDate, dates)
}

export function isBetween(current, start, end) {
  return current >= start && current < end
}
