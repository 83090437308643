import { useEffect, useState } from "react"
import axios from "axios"
import { createDate, getLuxonNow, getNearestDate } from "../util/dateHelpers"
import { conferenceEnd, conferenceStart } from "../util/various"
import { useAxiosWithAuthentication } from "./useAxiosWithAuthentication"
import { useCurrentEvent } from "./useCurrentEvent"

function getDates(startDate, endDate) {
  if (
    startDate.getDay() === endDate.getDay() &&
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth()
  ) {
    return [startDate]
  }

  return [startDate, endDate]
}

const possibleDates = getDates(
  conferenceStart.toJSDate(),
  conferenceEnd.toJSDate()
)

export function useScheduleTimeslots() {
  const [sessionsInSchedule, fetchSchedule] = useAxiosWithAuthentication({
    url: `/api/v2/schedule/`,
    method: "GET",
  })
  const [currentEvent] = useCurrentEvent()

  const [timeslots, setTimeslots] = useState([])
  const [dates, setDates] = useState({
    activeDate: getNearestDate(getLuxonNow().toJSDate(), possibleDates),
    possibleDates,
  })

  useEffect(() => {
    fetchSchedule().catch(() => {})
  }, [])

  useEffect(async () => {
    if (!sessionsInSchedule.data || !currentEvent.data) return

    const eventInfo = currentEvent.data.story.content.globalEvents
      .map((data) => ({
        title: data.title,
        start: createDate(data.start).toJSDate(),
        end: createDate(data.end).toJSDate(),
        isDraggable: false,
      }))
      .flat(2)

    const sessionTimeslots = []

    if (sessionsInSchedule.data?.data) {
      const allRequests = sessionsInSchedule.data.data.map(async (sessionId) =>
        axios
          .get(
            `${process.env.REACT_APP_STORYBLOK_URL}${sessionId}?version=${process.env.REACT_APP_STORYBLOK_VERSION}&token=${process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN}`
          )
          .then((req) => req.data.story)
          .catch((e) => e)
      )

      const result = await Promise.all(allRequests)

      result
        .filter((res) => !(res instanceof Error))
        .filter((res) => !!res)
        .forEach((session) => {
          session.content.slots.forEach((slot) => {
            sessionTimeslots.push({
              id: session.id,
              title: session.content.title,
              start: createDate(slot.start).toJSDate(),
              end: createDate(slot.end).toJSDate(),
              isDraggable: false,
            })
          })
        })
    }

    setTimeslots([...sessionTimeslots, ...eventInfo])
  }, [sessionsInSchedule.data, currentEvent.data])

  const setActiveDate = (date) => {
    setDates((state) => ({
      ...state,
      activeDate: date,
    }))
  }
  return {
    timeslots,
    loading: sessionsInSchedule.loading || currentEvent.loading || false,
    error: sessionsInSchedule.error || currentEvent.error || undefined,
    datesWithTalks: dates.possibleDates,
    selectedDate: dates.activeDate,
    setActiveDate,
  }
}
