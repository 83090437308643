import { Box, Container, Flex } from "@chakra-ui/react"
import { Link, useLocation } from "react-router-dom"
import { MdOutlineArrowBackIosNew } from "react-icons/md"
import React from "react"

export function TopNavigation() {
  const location = useLocation()

  return (
    <Box pt="20px" pb="10px" mb="10px" background="white" position="relative">
      <Box>
        <Container maxW="2xl">
          <Box position="relative" left={-1.5}>
            <Link to={{ pathname: location.state?.prevPath || "/sessions/" }}>
              <Flex alignItems="center">
                <MdOutlineArrowBackIosNew fontWeight="600" fontSize="20" />
                Back
              </Flex>
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
