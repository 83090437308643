import i18 from "i18next"
import { initReactI18next } from "react-i18next"
import { translations } from "../translations/translations"

export const GlobalTranslation = () => {
  i18.use(initReactI18next).init({
    resources: {
      en: { translation: translations.translationEN },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  })
}
