import { useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useAxiosWithAuthentication } from "./useAxiosWithAuthentication"

export function useScheduleForSession({ sessionId, onRequestDone }) {
  const { t } = useTranslation()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const [{ data: schedule, loading: isFetchScheduleLoading }, fetchSchedule] =
    useAxiosWithAuthentication({
      url: `/api/v2/schedule/${sessionId}`,
      method: "GET",
    })

  const [{ loading: isSaveScheduleLoading }, postScheduleForSession] =
    useAxiosWithAuthentication(
      {
        url: `/api/v2/schedule/${sessionId}`,
        method: "POST",
      },
      { manual: true }
    )

  const toggle = async () => {
    try {
      setIsLoading(true)
      const requestData = await postScheduleForSession()
      fetchSchedule()

      if (requestData.data.isInSchedule) {
        toast({
          position: "top",
          title: `${t("addSessionAlert")}`,
          status: "success",
          variant: "subtle",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          position: "top",
          title: `${t("removeSessionAlert")}`,
          status: "success",
          duration: 3000,
          variant: "subtle",
          isClosable: true,
        })
      }
      // eslint-disable-next-line
    } catch {
    } finally {
      setIsLoading(false)
    }

    if (onRequestDone) {
      onRequestDone()
    }
  }

  return {
    toggle,
    isInSchedule: schedule?.isInSchedule || false,
    isLoading: isLoading || isSaveScheduleLoading || isFetchScheduleLoading,
    isSaveScheduleLoading,
    isFetchScheduleLoading,
  }
}
