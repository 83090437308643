import { Box, Container, Text } from "@chakra-ui/react"
import React from "react"
import propTypes from "prop-types"

export function SectionHeadline({ children }) {
  return (
    <Box
      py="17px"
      borderBottom="0.3px solid #D5D5D5"
      mb="17px"
      background={{
        base: "#f5f5f5",
      }}
      position="sticky"
      top={0}
      left={0}
      right={0}
      zIndex={10}
    >
      <Container maxW="2xl">
        <Text color="#151515">{children}</Text>
      </Container>
    </Box>
  )
}

SectionHeadline.propTypes = {
  children: propTypes.node.isRequired,
}
