import React from "react"
import { NavLink } from "react-router-dom"
import { Flex, Text, Box, useMediaQuery } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ReactComponent as BookOpen } from "../assets/svg/book-open.svg"
import { ReactComponent as Schedule } from "../assets/svg/schedule.svg"
import { ReactComponent as Help } from "../assets/svg/help.svg"
import { ReactComponent as Feedback } from "../assets/svg/feedback.svg"

export function BottomNavigation() {
  const { t } = useTranslation()
  const [isLargerThan820] = useMediaQuery(["(min-width: 821px)"])
  const iconSizein820 = isLargerThan820 ? "40px" : "23px"
  const navs = [
    {
      name: "Sessions",
      route: "/sessions",
      icon: <BookOpen />,
    },
    {
      name: `${t("navBar.timeSchedule")}`,
      route: "/schedule",
      icon: <Schedule />,
    },
    {
      name: `${t("navBar.feedback")}`,
      route: "/feedback",
      icon: <Feedback />,
    },
    {
      name: "FAQ",
      route: "/faq",
      icon: <Help />,
    },
  ]

  return (
    <Box>
      <Box
        position="fixed"
        bottom={!isLargerThan820 ? 0 : null}
        borderTop={{
          base: "0.3px #D5D5D5 solid",
          md: undefined,
        }}
        top={isLargerThan820 ? 0 : null}
        left={0}
        width={isLargerThan820 ? "100px" : "100%"}
        bg="white"
        height={isLargerThan820 ? "100%" : "auto"}
      >
        <Flex
          direction={isLargerThan820 ? "column" : "row"}
          height="100%"
          justify="space-around"
          alignItems="center"
        >
          {navs.map((nav) => (
            <Box
              as={NavLink}
              width="100%"
              py={1.5}
              key={nav.route}
              to={nav.route}
              style={{
                color: "#151515",
              }}
              activeStyle={{
                color: "#5550FF",
              }}
            >
              <Flex direction="column" align="center">
                <Box width={iconSizein820} height={iconSizein820}>
                  {nav.icon}
                </Box>
                <Text fontSize={10} fontWeight="bold" mt={0.5}>
                  {nav.name}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
