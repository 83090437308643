import { Flex } from "@chakra-ui/react"
import React from "react"
import PropTypes from "prop-types"
import { formatDateLong } from "../../util/dateHelpers"
import { IoButton } from "../form/Button/IoButton"

export function ScheduleDaySelection({ dates, activeDate, onDateChange }) {
  return (
    <Flex flexWrap="wrap" gap={3}>
      {dates.map((date) => {
        const isCurrentDay = activeDate === date

        return (
          <IoButton
            as="button"
            fullWidth={false}
            hierarchy={isCurrentDay ? "vibrant" : "secondary"}
            background="onWhite"
            onClick={() => onDateChange(date)}
            key={JSON.stringify(date)}
          >
            {formatDateLong(date)}
          </IoButton>
        )
      })}
    </Flex>
  )
}

ScheduleDaySelection.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  activeDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func.isRequired,
}

ScheduleDaySelection.defaultProps = {
  activeDate: undefined,
}
