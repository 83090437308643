import { Flex, Box } from "@chakra-ui/react"
import propTypes from "prop-types"
import React from "react"
import { ReactComponent as Minus } from "../../../assets/svg/minus.svg"
import { ReactComponent as Plus } from "../../../assets/svg/plus.svg"
import { useScheduleForSession } from "../../../hooks/useScheduleForSession"
import { ThreeDotLoader } from "../../TheeDotLoader"

export function AddToScheduleButtonInternal({ onClick, isAdded, isLoading }) {
  const props = isLoading
    ? {
        bg: "white",
      }
    : {
        border: `1px solid ${isAdded ? "black" : "white"}`,
        bg: isAdded ? "white" : "black",
        color: isAdded ? "black" : "white",
      }

  return (
    <Flex
      as="button"
      width="48px"
      height="48px"
      position="absolute"
      bottom="10px"
      right="10px"
      borderRadius="full"
      type="button"
      onClick={onClick}
      justifyContent="center"
      alignItems="center"
      disabled={isLoading}
      {...props}
    >
      <Box width="24px" height="24px">
        {isLoading ? <ThreeDotLoader /> : isAdded ? <Minus /> : <Plus />}
      </Box>
    </Flex>
  )
}

AddToScheduleButtonInternal.propTypes = {
  onClick: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  isAdded: propTypes.bool.isRequired,
}

export function AddToScheduleButton({ sessionId }) {
  const sessionSchedule = useScheduleForSession({ sessionId })

  return (
    <AddToScheduleButtonInternal
      isAdded={sessionSchedule.isInSchedule}
      isLoading={sessionSchedule.isLoading}
      onClick={() => sessionSchedule.toggle()}
    />
  )
}

AddToScheduleButton.propTypes = {
  sessionId: propTypes.string.isRequired,
}
