import { Box } from "@chakra-ui/react"
import { Calendar, dateFnsLocalizer } from "react-big-calendar"
import React from "react"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import "react-big-calendar/lib/sass/styles.scss"
import "./ScheduleStyle.scss"
import { useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import deLocale from "date-fns/locale/de"
import { maxTime, minTime } from "../../util/dateHelpers"

const locales = {
  "de-DE": deLocale,
}

const dateLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export function ScheduleCalendar({ date, events }) {
  const history = useHistory()
  const location = useLocation()

  return (
    <div className="myCustomHeight">
      <Box>
        <Calendar
          views={{
            day: true,
          }}
          culture="de-DE"
          defaultView="day"
          date={date}
          localizer={dateLocalizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          step={15}
          min={minTime}
          max={maxTime}
          toolbar={false}
          onNavigate={() => {}}
          timeslots={undefined}
          selectable={false}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: !event.id && "#9735C5",
            },
          })}
          onSelectEvent={(e) => {
            if (!e.id) return
            history.push({
              pathname: `/sessions/${e.id}`,
              state: { prevPath: location.pathname },
            })
          }}
        />
      </Box>
    </div>
  )
}

ScheduleCalendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
}
