import React, { useState } from "react"
import {
  Modal,
  ModalHeader,
  Box,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Text,
  Textarea,
  FormLabel,
  HStack,
  VStack,
  Alert,
  useToast,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import propTypes from "prop-types"
import { useAxiosWithAuthentication } from "../../hooks/useAxiosWithAuthentication"
import { ReactComponent as Thumb1 } from "../../assets/svg/thumb-1.svg"
import { ReactComponent as Thumb2 } from "../../assets/svg/thumb-2.svg"
import { ReactComponent as Thumb3 } from "../../assets/svg/thumb-3.svg"
import { IoButton } from "../form/Button/IoButton"

export function WeAppFeedbackModal({ isOpen, onClose }) {
  const [value, setValue] = React.useState("")
  const [opinion, setOpinion] = useState("")
  const toast = useToast()
  const { t } = useTranslation()

  const ratingOptions = [
    {
      icon: Thumb1,
      title: `${t("feedbackForm.goodRate")}`,
      rating: 1,
    },
    {
      icon: Thumb2,
      title: `${t("feedbackForm.veryGoodRate")}`,
      rating: 2,
    },
    {
      icon: Thumb3,
      title: `${t("feedbackForm.megaGoodRate")}`,
      rating: 3,
    },
  ]

  const [{ loading, error }, postAppFeedback] = useAxiosWithAuthentication(
    {
      url: `/api/v2/wefeedback/appfeedback`,
      method: "POST",
    },
    { manual: true }
  )
  const submitHandler = (e) => {
    e.preventDefault()
    postAppFeedback({
      data: {
        rating: Number(value),
        questions: [
          {
            question: `${t("weAppFeedback.questionHowDoYouLikeTheApp")}`,
            answer: opinion,
          },
        ],
      },
    })
      .then(() =>
        toast({
          position: "top",
          title: `Your One App feedback has been submitted`,
          status: "success",
          variant: "subtle",
          duration: 3000,
          isClosable: true,
        })
      )
      .then(onClose)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent ml="3" mr="3">
        <ModalHeader>{t("weAppFeedback.modalHeader")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          <form onSubmit={submitHandler}>
            <Box mb={6}>
              <Text mb={3}>
                You can give feedback about the One App multiple times.
              </Text>
              <FormLabel mb={4} fontWeight="bold">
                {t("weAppFeedback.questionHowDoYouLikeTheApp")}
              </FormLabel>
              <HStack justify="center">
                {ratingOptions.map(({ rating, icon: Icon, title }) => (
                  <VStack
                    key={rating}
                    px={2}
                    as="button"
                    type="button"
                    onClick={() => setValue(rating)}
                    color={value === rating ? "vibrant.800" : "gray.600"}
                    w="100%"
                  >
                    <Icon />
                    <Text fontWeight="extrabold">{title}</Text>
                  </VStack>
                ))}
              </HStack>
            </Box>

            <Text mb={3} fontWeight="bold">
              {t("weAppFeedback.questionYourFeedback")}
            </Text>
            <Textarea
              onChange={(e) => setOpinion(e.target.value)}
              mb={3}
              placeholder={t("weAppFeedback.questionYourFeedbackPlaceholder")}
            />

            {(!value || !opinion) && (
              <Text>{t("weAppFeedback.modalHintText")}</Text>
            )}

            <IoButton
              as="button"
              type="submit"
              hierarchy="primary"
              background="onWhite"
              disabled={!value || !opinion}
            >
              {loading ? "Loading..." : t("feedbackForm.sendButton")}
            </IoButton>

            {error && (
              <Alert borderRadius="10px" boxShadow="2xl" status="error">
                {error.message}
              </Alert>
            )}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

WeAppFeedbackModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
}
