import {
  Alert,
  Box,
  FormLabel,
  HStack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ReactComponent as Thumb1 } from "../../../assets/svg/thumb-1.svg"
import { ReactComponent as Thumb2 } from "../../../assets/svg/thumb-2.svg"
import { ReactComponent as Thumb3 } from "../../../assets/svg/thumb-3.svg"
import { IoButton } from "../../form/Button/IoButton"

export function FeedbackForm({
  onSubmit,
  setFormState,
  error,
  loading,
  state,
  sessionTitle,
}) {
  const { t } = useTranslation()
  const ratingOptions = [
    {
      icon: Thumb1,
      title: `${t("feedbackForm.goodRate")}`,
      rating: 1,
    },
    {
      icon: Thumb2,
      title: `${t("feedbackForm.veryGoodRate")}`,
      rating: 2,
    },
    {
      icon: Thumb3,
      title: `${t("feedbackForm.megaGoodRate")}`,
      rating: 3,
    },
  ]

  return (
    <>
      <Text mb={6}>
        {t("feedbackForm.title")} “{sessionTitle}”.
      </Text>

      <form onSubmit={onSubmit}>
        <Box mb={6}>
          <FormLabel mb={3} fontWeight="bold">
            {t("feedbackForm.howDoYouLikeTheSessionRating")}
          </FormLabel>
          <HStack justify="center">
            {ratingOptions.map(({ rating, icon: Icon, title }) => (
              <VStack
                key={rating}
                px={2}
                as="button"
                type="button"
                onClick={() => setFormState({ ...state, rating })}
                color={state.rating === rating ? "vibrant.800" : "gray.600"}
                w="100%"
              >
                <Icon />
                <Text fontWeight="extrabold">{title}</Text>
              </VStack>
            ))}
          </HStack>
        </Box>

        <Box mb={6}>
          <FormLabel mb={1} fontWeight="bold" htmlFor="answer1">
            {t("feedbackForm.q1")}
          </FormLabel>
          <Textarea
            name="answer1"
            onChange={(e) => {
              setFormState({ ...state, answer1: e.target.value })
            }}
          />
        </Box>

        <Box mb={4}>
          <FormLabel mb={1} fontWeight="bold" htmlFor="answer2">
            {t("feedbackForm.q2")}
          </FormLabel>

          <Textarea
            name="answer2"
            onChange={(e) =>
              setFormState({ ...state, answer2: e.target.value })
            }
          />
        </Box>

        <Box mb={4}>
          <IoButton
            as="button"
            type="submit"
            hierarchy="primary"
            background="onWhite"
            disabled={state.rating === undefined}
          >
            {loading ? "Loading..." : t("feedbackForm.sendButton")}
          </IoButton>

          {error && (
            <Alert borderRadius="10px" boxShadow="2xl" status="error">
              {error.message}
            </Alert>
          )}
        </Box>
      </form>
    </>
  )
}

FeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  sessionTitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  state: PropTypes.shape({
    rating: PropTypes.number,
  }).isRequired,
}

FeedbackForm.defaultProps = {
  error: undefined,
}
