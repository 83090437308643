import { useOktaAuth } from "@okta/okta-react"
import { useHistory } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Box, Container, VStack, Text, Flex } from "@chakra-ui/react"
import React from "react"
import { AiFillHeart } from "react-icons/ai"
import { useTranslation } from "react-i18next"
import { ReactComponent as OneConferenceLogo } from "../../assets/svg/interone_one_conference_white.svg"
import animatedInteroneLogoGifSrc from "../../assets/gif/15f.gif"
import { IoButton } from "../form/Button/IoButton"

export function LoginView() {
  const { oktaAuth, authState } = useOktaAuth()
  const { t } = useTranslation()
  const history = useHistory()

  if (authState?.isAuthenticated) {
    history.push("/")
  }

  return (
    <>
      <Helmet>
        <title>Login | One App</title>
      </Helmet>

      <VStack pt="10vh" justifyContent="space-between">
        <Box w="100%">
          <Container>
            <Flex justifyContent="center">
              <Box w="80%">
                <Box mb="70px">
                  <img
                    src={animatedInteroneLogoGifSrc}
                    alt="Animated Interone Logo"
                  />
                </Box>

                <Box mb="40px">
                  <OneConferenceLogo />
                </Box>
              </Box>
            </Flex>

            <Box
              px={{
                md: "10%",
              }}
            >
              <Text
                w="100%"
                align="center"
                mb={14}
                dangerouslySetInnerHTML={{ __html: t("login.welcomeText") }}
              />

              <IoButton
                as="button"
                hierarchy="primary"
                background="onWhite"
                onClick={() => oktaAuth.signInWithRedirect()}
              >
                {t("login.buttonText")}
              </IoButton>

              <Text mt={4} w="100%" align="center" fontSize={14}>
                {t("login.loginHelperTextUsername")}
                <br />
                {t("login.loginHelperTextPassword")}
              </Text>
            </Box>
          </Container>
        </Box>

        <Box position="fixed" bottom={0} left={0} width="100%" py={2}>
          <Text color="gray.700" textAlign="center">
            Made with{" "}
            <Box display="inline-block" as="span">
              <AiFillHeart />
            </Box>{" "}
            by Ex-SUT
          </Text>
        </Box>
      </VStack>
    </>
  )
}
