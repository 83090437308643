import { DateTime } from "luxon"

export const timeStampsToDurations = (timeSlots) => {
  const totalDuration = timeSlots
    .map((timeSlot) => {
      const startingTime = DateTime.fromISO(timeSlot.start.replace(" ", "T"))
      const endingTime = DateTime.fromISO(timeSlot.end.replace(" ", "T"))

      return endingTime.diff(startingTime, [
        "years",
        "months",
        "days",
        "hours",
        "minutes",
      ])
    })
    .reduce((previousValue, currentValue) => {
      if (!previousValue) return currentValue

      return previousValue.plus(currentValue)
    }, undefined)

  return totalDuration
}
