import React from "react"
import { Box, Button, useDisclosure, Center } from "@chakra-ui/react"
import { MdFeedback } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { WeAppFeedbackModal } from "./modals/WeAppFeedbackModal"

export function AppFooter() {
  const { t } = useTranslation()

  const feedbackForm = useDisclosure()

  return (
    <Box marginTop="auto" mt={5}>
      <Box w="100%" p={3} borderRadius={10}>
        <Center>
          <Button
            shadow="2xl"
            size="sm"
            leftIcon={<MdFeedback size={20} />}
            onClick={feedbackForm.onOpen}
            fontSize="16"
            bg="lightgrey"
          >
            {t("footer.buttonTitle")}
          </Button>
        </Center>
      </Box>

      <WeAppFeedbackModal
        isOpen={feedbackForm.isOpen}
        onClose={feedbackForm.onClose}
      />
    </Box>
  )
}
