import React from "react"
import { Box, Flex, Heading, Icon, SimpleGrid } from "@chakra-ui/react"
import { AiOutlineClockCircle } from "react-icons/ai"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import { SessionCard } from "./SessionCard"
import { formatDateToTime } from "../../../util/dateHelpers"

export const SessionsList = ({
  sessions,
  onSessionCardClick,
  onlyShowMinimumInformation,
}) =>
  sessions.map((data) => (
    <Box key={data.id} style={{ display: "flex" }}>
      <SessionCard
        onClick={onSessionCardClick}
        session={data}
        onlyShowMinimumInformation={onlyShowMinimumInformation}
      />
    </Box>
  ))

SessionsList.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSessionCardClick: PropTypes.func.isRequired,
  onlyShowMinimumInformation: PropTypes.bool,
}

SessionsList.defaultProps = {
  onlyShowMinimumInformation: undefined,
}

export function SessionListByTime({ sessionByTime, onSessionCardClick }) {
  return (
    <Box mb={10} id={formatDateToTime(sessionByTime.time)}>
      <Flex alignItems="center" sx={{ position: "sticky", top: 0 }} mb={4}>
        <Icon as={AiOutlineClockCircle} size={16} />
        <Heading ml={2} size="sm">
          {formatDateToTime(sessionByTime.time)} Uhr
        </Heading>
      </Flex>

      <SimpleGrid columns={[1, 1, 1, 2]} spacing="24px">
        <SessionsList
          onSessionCardClick={onSessionCardClick}
          sessions={sessionByTime.items}
          onlyShowMinimumInformation={false}
        />
      </SimpleGrid>
    </Box>
  )
}

SessionListByTime.propTypes = {
  sessionByTime: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    time: PropTypes.instanceOf(DateTime),
  }).isRequired,
  onSessionCardClick: PropTypes.func.isRequired,
}
