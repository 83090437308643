import React, { useEffect } from "react"
import { Box, Grid, useMediaQuery } from "@chakra-ui/react"
import { useOktaAuth } from "@okta/okta-react"
import { BottomNavigation } from "../components/BottomNavigation"

export const withBottomNavigation = (ReactComponent) =>
  // eslint-disable-next-line func-names
  function () {
    const [isLargerThan820] = useMediaQuery(["(min-width: 821px)"])
    const { oktaAuth } = useOktaAuth()

    useEffect(() => {
      oktaAuth.session.refresh().catch(() => {})
    }, [oktaAuth])

    return (
      <div>
        {isLargerThan820 ? (
          <Grid gridTemplateColumns="100px 1fr">
            <BottomNavigation />
            <Box>
              <ReactComponent />
            </Box>
          </Grid>
        ) : (
          <>
            <Box pb="65px">
              <ReactComponent />
            </Box>
            <BottomNavigation />
          </>
        )}
      </div>
    )
  }
