import React, { useState, useEffect } from "react"
import {
  Alert,
  Box,
  Container,
  Heading,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react"
import PullToRefresh from "react-simple-pull-to-refresh"
import { useTranslation } from "react-i18next"
import { SessionLoadingList } from "./SessionLoadingList"
import { SessionListByTime, SessionsList } from "./SessionsList"
import { formatDateLong } from "../../../util/dateHelpers"
import { PageHeader } from "../../PageHeader"
import { HighlightedSessions } from "../HighlightedSessions/HighlightedSessions"
import { SessionSearch } from "../SessionSearch/SessionSearch"
import "./SessionViewStyle.scss"
import { IntroductionModal } from "../../introduction/IntroductionModal"
import {
  groupByDate,
  groupByStartingTime,
  sortByDate,
  sortByStartingTime,
} from "../../../util/timeHelpers"
import { isConferenceNow } from "../../../util/various"
import { useSessions } from "../../../hooks/useSessions"

export function SessionView() {
  const { t } = useTranslation()
  const [sessionsByGroup, setSessionsByGroup] = useState({})
  const [sessionSearchResults, setSessionSearchResults] = useState(undefined)

  const [{ data, loading, error }, fetchSessions] = useSessions()

  useEffect(() => {
    if (!data || !data.stories) return

    const sortedByDate = sortByDate(
      data.stories.filter((session) => {
        if (Array.isArray(session.content?.slots)) {
          return true
        }

        // eslint-disable-next-line no-console
        console.warn(
          `Session has no slots, ignoring session with ${session.id}.`
        )
        return false
      })
    )
    const groupedByDate = groupByDate(sortedByDate)

    const groupedAndSorted = {}

    Object.keys(groupedByDate).forEach((key) => {
      const sortedByStartingTime = sortByStartingTime(groupedByDate[key])
      const groupedByStartingTime = groupByStartingTime(sortedByStartingTime)

      groupedAndSorted[key] = groupedByStartingTime
    })

    setSessionsByGroup(groupedAndSorted)
  }, [data])

  if (loading || error || !data.stories) {
    return (
      <PullToRefresh onRefresh={fetchSessions}>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Container maxW="container.xl">
            <PageHeader title="Sessions" />
            {loading && (
              <SimpleGrid columns={[1, 1, 1, 2]} spacing={4}>
                <SessionLoadingList />
              </SimpleGrid>
            )}
            {(error || !data?.stories) && (
              <Alert
                maxWidth="560px"
                m="auto"
                borderRadius="10px"
                boxShadow="2xl"
                status="error"
              >
                {error?.message || "An unknown error occured"}
              </Alert>
            )}
          </Container>
        </Box>
      </PullToRefresh>
    )
  }

  return (
    <PullToRefresh onRefresh={fetchSessions}>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <IntroductionModal />
        <Container maxW="container.xl">
          <PageHeader title="Sessions" />
        </Container>
        <Box>
          {isConferenceNow() && <HighlightedSessions sessions={data.stories} />}
          <SessionSearch
            sessions={data.stories}
            onSearchResults={(searchResults) => {
              setSessionSearchResults(searchResults)
            }}
          />
          <Container maxW="container.xl">
            {sessionSearchResults?.length === 0 && (
              <Alert
                maxWidth="560px"
                m="auto"
                borderRadius="10px"
                boxShadow="2xl"
                status="info"
              >
                {t("filterAlert")}
              </Alert>
            )}

            {sessionSearchResults?.length > 0 && (
              <Container maxW="2xl">
                <SimpleGrid columns={1} spacing={4}>
                  <SessionsList
                    onSessionCardClick={() => {}}
                    sessions={sessionSearchResults}
                  />
                </SimpleGrid>
              </Container>
            )}
            {!sessionSearchResults &&
            Object.keys(sessionsByGroup).length > 1 ? (
              <Tabs>
                <TabList>
                  {Object.keys(sessionsByGroup).map((dateIsoString) => (
                    <Tab key={dateIsoString}>
                      <Heading fontSize={{ base: 16, lg: 18 }}>
                        {formatDateLong(new Date(dateIsoString))}
                      </Heading>
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {Object.keys(sessionsByGroup).map((dateIsoString) => (
                    <TabPanel pt={5} p={3} key={dateIsoString}>
                      {Object.values(sessionsByGroup[dateIsoString]).map(
                        (sessionByTime) => (
                          <SessionListByTime
                            onSessionCardClick={() => {}}
                            sessionByTime={sessionByTime}
                            key={sessionByTime.time}
                          />
                        )
                      )}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            ) : (
              !sessionSearchResults &&
              Object.values(sessionsByGroup)
                .map((sessionByDay) => Object.values(sessionByDay))
                .flat(1)
                .map((sessionByTime) => (
                  <SessionListByTime
                    onSessionCardClick={() => {}}
                    sessionByTime={sessionByTime}
                    key={sessionByTime.time}
                  />
                ))
            )}
          </Container>
        </Box>
      </Box>
    </PullToRefresh>
  )
}
