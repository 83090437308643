import React, { useEffect } from "react"
import {
  Text,
  Heading,
  Container,
  Alert,
  useDisclosure,
  Box,
  useMediaQuery,
  SimpleGrid,
} from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import PullToRefresh from "react-simple-pull-to-refresh"
import { useTranslation } from "react-i18next"
import { SessionDetailViewLoading } from "./SessionDetailViewLoading"
import { Tags } from "../Tags"
import {
  createDate,
  formatDateLong,
  formatDateToTime,
} from "../../../util/dateHelpers"
import { SessionFeedback } from "../SessionFeedback/SessionFeedback"
import { TopRating } from "../SessionListView/TopRating"
import { onlyDateWithoutTime } from "../../../util/convertDateAndTime"
import useSession from "../../../util/useSession"
import { useAxiosWithAuthentication } from "../../../hooks/useAxiosWithAuthentication"
import { SpeakerCard } from "../SpeakerCard"
import { SessionCardBadges } from "../SessionListView/SessionCardBadges"
import { useScheduleForSession } from "../../../hooks/useScheduleForSession"
import { TopNavigation } from "./TopNavigation"
import { IoButton } from "../../form/Button/IoButton"

export function SessionDetailView() {
  const [isLargerThan480] = useMediaQuery(["(min-width: 481px)"])
  const { t } = useTranslation()
  const { id } = useParams()
  const {
    data,
    loading,
    error,
    refetch,
    sessionType,
    speakers,
    isTopSession,
    timing,
  } = useSession(id)

  const [{ data: participants }, fetchParticipants] =
    useAxiosWithAuthentication({
      url: `/api/v2/schedule/${id}/participants`,
      method: "GET",
    })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const scheduleForSession = useScheduleForSession({
    sessionId: id,
    onRequestDone: () => {
      fetchParticipants()
    },
  })

  const [
    { data: hasGivenFeedbackData, loading: hasGivenFeedbackDataLoading },
    fetchHasGivenFeedback,
  ] = useAxiosWithAuthentication({
    url: `/api/v2/sessions/${id}/feedback`,
    method: "GET",
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) {
    return (
      <>
        <Helmet>
          <title>Loading | Session | One App</title>
        </Helmet>
        <TopNavigation />
        <SessionDetailViewLoading />
      </>
    )
  }
  if (error) {
    return (
      <>
        <Helmet>
          <title>Error | Session | One App</title>
        </Helmet>
        <TopNavigation />
        <Container>
          <Alert borderRadius="10px" boxShadow="2xl" status="error">
            {error.message}
          </Alert>
        </Container>
      </>
    )
  }

  if (!data.story) {
    return (
      <>
        <Helmet>
          <title>{t("sessionViewAlert")} | One App</title>
        </Helmet>
        <TopNavigation />
        <Container maxW="2xl" mt={8}>
          <Heading fontSize="3xl" mb={1}>
            {t("sessionViewAlert")}
          </Heading>
        </Container>
      </>
    )
  }

  return (
    <PullToRefresh onRefresh={refetch}>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Helmet>
          <title>{data.story.content.title} | One App</title>
        </Helmet>
        <TopNavigation />
        <Container maxW="2xl" pt={5}>
          <SessionCardBadges
            isSessionUpcoming={timing.isSessionUpcoming}
            oldSession={timing.isOldSession}
            isSessionNow={timing.isLive}
          />
          <Heading
            color="#DA0074"
            fontSize={isLargerThan480 ? "3xl" : "2xl"}
            mb={3}
            lineHeight={1.05}
          >
            {data.story.content.title}
          </Heading>

          <SimpleGrid spacing={[2, 4]} columns={[1, 2]}>
            {speakers.map((speaker) => (
              <SpeakerCard key={speaker.uuid} speaker={speaker.content} />
            ))}
          </SimpleGrid>
        </Container>

        <Container maxW="2xl" mt={3}>
          <Tags tags={data?.story.content.sessionTags || []}>
            {isTopSession && <TopRating />}
          </Tags>

          <SimpleGrid mt={5} mb={5} columns={[2, 3]} spacing={4}>
            <Box>
              <Heading fontSize="lg">{t("sessionViewRoom")}</Heading>
              <Text>{data.story.content.room}</Text>
            </Box>

            <Box>
              <Heading fontSize="lg">Format</Heading>
              <Text>{sessionType?.title}</Text>
            </Box>

            <Box>
              <Heading fontSize="lg">Participants</Heading>
              <Text>
                {!participants && "loading ..."}
                {participants?.numberOfParticipants !== 0
                  ? participants?.numberOfParticipants
                  : "none"}
              </Text>
            </Box>
          </SimpleGrid>

          <Box mb={8}>
            <Heading pt={2} fontSize="lg">
              {data.story.content.slots.length > 1 ? "Slots" : "Slot"}
            </Heading>

            {data.story.content.slots.map((slot) => (
              <Box key={slot._uid} mb={2}>
                <Text mb={2}>
                  {formatDateLong(new Date(onlyDateWithoutTime(slot.start)))}
                </Text>
                <Text key={slot.id} mb={1}>
                  {formatDateToTime(createDate(slot.start).toJSDate())}
                  &nbsp; - &nbsp;
                  {formatDateToTime(createDate(slot.end).toJSDate())}
                  &nbsp;{t("sessionViewHourUnit")}
                </Text>
              </Box>
            ))}
          </Box>

          <Box mb={8}>
            <Box mb={5}>
              <IoButton
                as="button"
                type="button"
                hierarchy={
                  scheduleForSession.isInSchedule ? "secondary" : "primary"
                }
                background="onWhite"
                onClick={() => scheduleForSession.toggle()}
                disabled={scheduleForSession.isLoading}
              >
                {scheduleForSession.isLoading
                  ? "Loading ..."
                  : scheduleForSession.isInSchedule
                  ? `${t("removeSessionButton")}`
                  : `${t("addSessionButton")}`}
              </IoButton>
            </Box>

            <Box mb={8}>
              <IoButton
                disabled={
                  hasGivenFeedbackData?.hasGivenFeedback ||
                  hasGivenFeedbackDataLoading
                }
                as="button"
                type="button"
                hierarchy={
                  scheduleForSession.isInSchedule ? "primary" : "secondary"
                }
                background="onWhite"
                onClick={onOpen}
              >
                {hasGivenFeedbackDataLoading
                  ? "Loading ..."
                  : hasGivenFeedbackData?.hasGivenFeedback
                  ? t("thankYouFeedback")
                  : t("giveFeedback")}
              </IoButton>
            </Box>
          </Box>

          <Box mb={8}>
            <Heading pt="5" mb={2} fontSize="xl">
              {t("sessionViewDescription")}
            </Heading>
            <Text
              as="div"
              fontSize="lg"
              mb={5}
              dangerouslySetInnerHTML={{
                __html: data.story.content.description.replaceAll(
                  "\n",
                  "<br />"
                ),
              }}
            />
          </Box>

          <SessionFeedback
            isOpen={isOpen}
            onClose={onClose}
            title={data.story.content.title}
            id={id}
            onFeedback={() => {
              fetchHasGivenFeedback()
            }}
          />
        </Container>
      </Box>
    </PullToRefresh>
  )
}
